import React from "react"
import AboutUsSectionStyle from "./AboutUsSectionStyle.module.styl"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import Image from "gatsby-image"

const AboutUsSection = ({ aboutUsSectionImage }) => {
  return (
    <section className={AboutUsSectionStyle.about_us_section}>
      <div className={AboutUsSectionStyle.about_us_section_wrapper}>
        <div className={AboutUsSectionStyle.about_us_main_text}>
          <h2>Защо да изберете Special Security Group</h2>
          <h3>
            Ние осъществяваме сигнално-охранителна дейност на територията на област Ямбол.
          </h3>
          <p>
            От създаването си, фирмата генерира и внедрява иновации в
            корпоративната и частна сигурност, които я извеждат на челните места
            в тази област. Ние предлагаме екип от най-добре обучените
            специалисти в сферата сигурност.Благодарение на професионалистите, с
            които разполагаме, имаме възможност да предложим гъвкави,
            индивидуални решения за охрана по изисквания на всички видове
            клиенти. Вашата сигурност е наш приоритет!
          </p>
          <Link className={AboutUsSectionStyle.see_more_btn} to={"/about-us"}>
            Виж още <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
        <div className={AboutUsSectionStyle.image_holder}>
          <Image
            className={AboutUsSectionStyle.image}
            fluid={aboutUsSectionImage[0].node.childImageSharp.fluid}
          />
        </div>
      </div>
    </section>
  )
}

export default AboutUsSection
