import React from "react"
import HeroSectionStyle from "./HeroSectionStyle.module.styl"
import Image from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCctv,
  faClock,
  faShieldCheck,
} from "@fortawesome/pro-regular-svg-icons"
import { faUsers } from "@fortawesome/pro-duotone-svg-icons/faUsers"

const HeroSection = ({ homeHeroSectionImage }) => {
  return (
    <section className={HeroSectionStyle.hero_section}>
      <Image
        className={HeroSectionStyle.hero_image}
        fluid={homeHeroSectionImage[0].node.childImageSharp.fluid}
      />
      <div className={HeroSectionStyle.main_info_wrapper}>
        <div className={HeroSectionStyle.main_info}>
          <div className={HeroSectionStyle.title_wrapper}>
            <span>ДОБРЕ ДОШЛИ В SPECIAL SECURITY GROUP</span>
            <h1>Вашата сигурност е наш приоритет!</h1>
            <a href="#contacts" className={HeroSectionStyle.contact_us_btn}>
              Свържи се с нас
            </a>
          </div>
        </div>
      </div>
      <div className={HeroSectionStyle.features_wrapper}>
        <div className={HeroSectionStyle.features}>
          <div className={HeroSectionStyle.feature_item}>
            <div className={HeroSectionStyle.feature_icon_holder}>
              <FontAwesomeIcon icon={faClock} />{" "}
            </div>

            <span>Бърза реакция </span>
          </div>
          <div className={HeroSectionStyle.feature_item}>
            <div className={HeroSectionStyle.feature_icon_holder}>
              <FontAwesomeIcon icon={faCctv} />{" "}
            </div>

            <span>24-часово наблюдение </span>
          </div>
          <div className={HeroSectionStyle.feature_item}>
            <div className={HeroSectionStyle.feature_icon_holder}>
              <FontAwesomeIcon icon={faShieldCheck} />{" "}
            </div>

            <span>Най-добро покритие </span>
          </div>
          <div className={HeroSectionStyle.feature_item}>
            <div className={HeroSectionStyle.feature_icon_holder}>
              <FontAwesomeIcon icon={faUsers} />{" "}
            </div>

            <span> Oборудван екип </span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSection
