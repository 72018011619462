import React from "react"
import HeroSection from "./components/heroSection/HeroSection"
import AboutUsSection from "./components/aboutUsSection/AboutUsSection"
import ServicesSection from "./components/servicesSection/ServicesSection"
import ServiceModal from "./components/servicesSection/components/serviceModal/ServiceModal"
import SODImage from "./../../assets/images/Rectangle 62@2x-min.png"
import EventsImage from "./../../assets/images/Rectangle 63@2x-min.png"
import LandImage from "./../../assets/images/Rectangle 60@2x-min.png"
import SecurityGuardsImage from "./../../assets/images/Rectangle 59@2x-min.png"
import VideoImage from "./../../assets/images/Rectangle 61@2x-min.png"
import SystemSecurity from "./../../assets/images/security-camera.jpg"
import ContactsTemplate from "../contacts/Contacts.template"

class HomeTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0,
      sodModalIsOpen: false,
      videoServiceModalIsOpen: false,
      securityEventsModalIsOpen: false,
      securityOfLandsModalIsOpen: false,
      systemSecurity: false,
    }
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth })
  }
  toggleSodModal = () => {
    this.setState({ sodModalIsOpen: !this.state.sodModalIsOpen })
  }
  toggleVideoServiceModal = () => {
    this.setState({
      videoServiceModalIsOpen: !this.state.videoServiceModalIsOpen,
    })
  }
  toggleSecurityGuardsModal = () => {
    this.setState({
      securityGuardsModalIsOpen: !this.state.securityGuardsModalIsOpen,
    })
  }
  toggleSecurityEventsModal = () => {
    this.setState({
      securityEventsModalIsOpen: !this.state.securityEventsModalIsOpen,
    })
  }
  toggleSecurityOfLandsModal = () => {
    this.setState({
      securityOfLandsModalIsOpen: !this.state.securityOfLandsModalIsOpen,
    })
  }
  toggleSystemSecurity = () => {
    this.setState({
      systemSecurity: !this.state.systemSecurity,
    })
  }

  render() {
    const { homeHeroSectionImage, aboutUsSectionImage } = this.props
    return (
      <>
        <HeroSection homeHeroSectionImage={homeHeroSectionImage} />
        <AboutUsSection aboutUsSectionImage={aboutUsSectionImage} />
        <ServicesSection
          toggleSodModal={this.toggleSodModal}
          toggleVideoServiceModal={this.toggleVideoServiceModal}
          width={this.state.width}
          toggleSecurityEventsModal={this.toggleSecurityEventsModal}
          toggleSecurityGuardsModal={this.toggleSecurityGuardsModal}
          toggleSecurityOfLandsModal={this.toggleSecurityOfLandsModal}
          toggleSystemSecurity={this.toggleSystemSecurity}
        />
        <ContactsTemplate />
        {this.state.sodModalIsOpen === true && (
          <ServiceModal
            closeModal={this.toggleSodModal}
            isModalOpen={this.state.sodModalIsOpen}
            service_title={"СОД"}
            more_info={"(Сигнално-охранителна дейност)"}
            advantage1={"Патрулна охрана"}
            advantage2={"Най-добро покритие на град Ямбол"}
            advantage3={"Бърза реакция до 2 минути"}
            advantage4={"Въоражен патрул"}
            service_image={SODImage}
            description={
              "Сигнално-охранителната дейност (СОД) , предоставена от „Спешъл Сикюрити Груп“ ООД е обезпечена чрез постоянна комуникация между нашите патрули и диспечерите в денонощният дежурен център. Централата за мониторинг и практиките, които са въведени, осигуряват най-добро изпълнение на задълженията по охрана на различните видове обекти. Служителите на фирмата са оборудвани с нужните екипировка и автомобили, които улесняват бързата реакция и покритие на различните райони."
            }
          />
        )}
        {this.state.videoServiceModalIsOpen === true && (
          <ServiceModal
            closeModal={this.toggleVideoServiceModal}
            isModalOpen={this.state.videoServiceModalIsOpen}
            service_title={"Видеонаблюдение"}
            advantage1={"24-часово, непрекъснато наблюдение на вашият обект"}
            advantage2={"100% бърза и адекватна реакция"}
            advantage3={"Охрана на движимо имущество"}
            advantage4={
              "Подходяща услуга за всякакъв вид обекти - частни и фирмени"
            }
            service_image={VideoImage}
            description={
              "Видеонаблюдението и видеомониторинга са услуги, които набират все по голяма популярност сред нашите клиенти. „Спешъл Сикюрити Груп“ ООД се е специализирала в изграждането на такива системи, като предлагаме проектиране, изпълнение и въвеждане в експлоатация. Консултацията по изграждането на обекти е безплатна, като се дават различни решения в комбинация с други наши услуги." +
              "Видеонаблюдението е вариант, който може да ви осигури спокойствие, като допълнително увеличи нивото ви на сигурност. Тази услуга предлага монтирането на камери, които остават за наблюдение от самия клиент и се разчита на сигнал подаден към нас от СОТ система." +
              "Видеомониторинга е най-съвременният вариант на охрана. Тази услуга осигурява по-кратко време за реакция, тъй като изградената система за видеонаблюдение се обслужва от диспечерите в нашият Денонощен дежурен център. Сигналът се подава на моторизираните патрули, като се прецизира с точност мястото на нарушение."
            }
          />
        )}{" "}
        {this.state.securityGuardsModalIsOpen === true && (
          <ServiceModal
            closeModal={this.toggleSecurityGuardsModal}
            isModalOpen={this.state.securityGuardsModalIsOpen}
            service_title={"Физическа охрана"}
            advantage1={"Подбор на охранители"}
            advantage2={"Професионално оборудван екип "}
            advantage3={"Завишен контрол на изпълнение на задълженията"}
            service_image={SecurityGuardsImage}
            description={
              "„Спешъл Сикюрити Груп“ ООД предлага физическа охранана своите клиенти,които са: търговски центрове; държавни и общински учреждения; складови и производствени бази; земеделски и животновъдни обекти. Фирмата инвестира в обучения, екипировка и оборудване, за да могат служителите да са на най-високо ниво и да реагират във всеки един момент. Мениджър контрол е в постоянен контакт с охранителите, като осъществява завишен контрол на изпълнение на задълженията."
            }
          />
        )}
        {this.state.securityEventsModalIsOpen === true && (
          <ServiceModal
            closeModal={this.toggleSecurityEventsModal}
            isModalOpen={this.state.securityEventsModalIsOpen}
            service_title={"Охрана на мероприятия"}
            advantage1={"Спортни събития"}
            advantage2={"Културни и масови събития"}
            service_image={EventsImage}
            description={
              "Охраната на мероприятия предлагана от „Спешъл Сикюрити Груп“ ООД е услуга, която се осъществява от нашите професионално обучени и оборудвани специалисти. При масови събирания и събития е задължително присъствието на охрана, която гарантира процеса на организация от начало до край."
            }
          />
        )}{" "}
        {this.state.securityOfLandsModalIsOpen === true && (
          <ServiceModal
            closeModal={this.toggleSecurityOfLandsModal}
            isModalOpen={this.state.securityOfLandsModalIsOpen}
            service_title={"Охрана на земедлски площи и продукция"}
            advantage1={"Жива охрана"}
            advantage2={"Камери"}
            advantage3={"Термо камери"}
            advantage4={"Уреди за нощно виждане"}
            advantage5={"Дрон"}
            service_image={LandImage}
            description={
              "Охраната на земеделски площи , земеделска продукция и животновъдни обекти е услуга предлагана от „Спешъл Сикюрити Груп“ ООД, която придобива все по-широк интерес. Селското стопанство е един от основните отрасли на българската икономика и като такъв има нужда от адекватни мерки за охрана, които не трябва да се ограничават само с жива, патрулна охрана. Ние предлагаме иновативни методи, които се осъществяват с модерно оборудване и са съобразени с индивидуалните изискванията на всеки наш клиент."
            }
          />
        )}
        {this.state.systemSecurity === true && (
          <ServiceModal
            closeModal={this.toggleSystemSecurity}
            isModalOpen={this.state.systemSecurity}
            service_title={"Охранителни системи"}
            advantage1={
              "Денонощен дежурен център за мониторинг и технически екипи"
            }
            advantage2={"Гаранционно и следгаранционно обслужване"}
            advantage3={
              "Инсталация и поддръжка на всички видове охранителни системи"
            }
            advantage4={"Инсталация на пожароизвестителни системи"}
            service_image={SystemSecurity}
            description={
              "Денонощният център за мониторинг е изграден с най-модерна техника, гарантираща надеждност и стабилност. Мрежовата свързаност на центъра е осигурен чрез партньорство с най-големият доставчик на интернет в региона. Дежурните екипи на „Спешъл Сикюрити Груп“ ООД, са обучени да отстраняват и поддържат всички видове оборудване, което се предлага на нашите клиенти. Техниката, която фирмата използва в охранителната си дейност е с най-високо ниво на защита и ниски разходи по поддръжка. След изтичане на гаранционният период на техниката, фирмата предлага гъвкави абонаменти планове за поддръжка"
            }
          />
        )}
      </>
    )
  }
}

export default HomeTemplate
