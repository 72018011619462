import React from "react"
import { Modal, ModalBody } from "react-bootstrap"
import ServiceModalStyle from "./ServiceModalStyle.module.styl"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons"

const ServiceModal = ({
  closeModal,
  isModalOpen,
  service_title,
  more_info,
  advantage1,
  advantage2,
  advantage3,
  advantage4,
  advantage5,
  service_image,
  description,
}) => {
  return (
    <Modal className="service_modal" onHide={closeModal} show={isModalOpen}>
      <ModalBody className={ServiceModalStyle.service_body_wrapper}>
        <div
          onClick={closeModal}
          onKeyDown={closeModal}
          role="button"
          tabIndex="0"
          className={ServiceModalStyle.exit_icon_holder}
        >
          <FontAwesomeIcon
            className={ServiceModalStyle.exit_icon}
            icon={faTimes}
          />
        </div>
        <div className={ServiceModalStyle.main_info_holder}>
          <div className={ServiceModalStyle.image_holder}>
            <img src={service_image} alt="service" />
            <div className={ServiceModalStyle.background_overlay} />
          </div>
          <h4>{service_title}</h4>
          {more_info && (
            <span className={ServiceModalStyle.more_info_under_title}>
              {more_info}
            </span>
          )}
        </div>
        <div className={ServiceModalStyle.content_holder}>
          <p>
            Възползвайте се от тази услуга и ние ще се погрижим Вие да получите
            :
          </p>
          <div className={ServiceModalStyle.service_option}>
            <FontAwesomeIcon icon={faCheckCircle} />
            <div>{advantage1}</div>
          </div>
          <div className={ServiceModalStyle.service_option}>
            <FontAwesomeIcon icon={faCheckCircle} />
            <div>{advantage2}</div>
          </div>
          {advantage3 && (
            <div className={ServiceModalStyle.service_option}>
              <FontAwesomeIcon icon={faCheckCircle} />
              <div>{advantage3}</div>
            </div>
          )}
          {advantage4 && (
            <div className={ServiceModalStyle.service_option}>
              <FontAwesomeIcon icon={faCheckCircle} />
              <div>{advantage4}</div>
            </div>
          )}
          {advantage5 && (
            <div className={ServiceModalStyle.service_option}>
              <FontAwesomeIcon icon={faCheckCircle} />
              <div>{advantage5}</div>
            </div>
          )}
          <div className={ServiceModalStyle.description_holder}>
            <div className={ServiceModalStyle.description}>{description}</div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ServiceModal
