import React from "react"
import ServicesSectionStyle from "./ServicesSectionStyle.module.styl"
import Service1 from "./../../../../assets/images/Rectangle 62@2x-min.png"
import Service2 from "./../../../../assets/images/Rectangle 60@2x-min.png"
import Service3 from "./../../../../assets/images/Rectangle 59@2x-min.png"
import Service4 from "./../../../../assets/images/Rectangle 61@2x-min.png"
import Service5 from "./../../../../assets/images/Rectangle 63@2x-min.png"
import Service7 from "./../../../../assets/images/security-camera.jpg"

const ServicesSection = ({
  width,
  toggleVideoServiceModal,
  toggleSodModal,
  toggleSecurityGuardsModal,
  toggleSecurityEventsModal,
  toggleSecurityOfLandsModal,
  toggleSystemSecurity,
}) => {
  return (
    <section
      className={
        width <= 992
          ? ServicesSectionStyle.services_section_without_background
          : ServicesSectionStyle.services_section
      }
    >
      <div className={ServicesSectionStyle.services_section_wrapper}>
        <div className="row">
          {width <= 760 ? (
            <div className="col-12">
              <div className={ServicesSectionStyle.service_info}>
                <h2>Нашите услуги</h2>
                <p>
                  Тук може да видите нашият пълен пакет услуги подходящи за
                  всеки тип клиенти. Благодарение на силно мотивираният ни екип,
                  фирмата е развила всички тези дейности и до днес продължава да
                  разгръща своя потенциал.
                </p>
              </div>
            </div>
          ) : null}
          <div
            className="col-lg-3 col-md-3 col-6 "
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              onClick={toggleSodModal}
              onKeyDown={toggleSodModal}
              role="button"
              tabIndex="0"
              className={ServicesSectionStyle.service}
            >
              <img src={Service1} alt={"service"} />
              <div className={ServicesSectionStyle.title_holder}>
                <h4>СОД</h4>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-3 col-6"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              onClick={toggleSecurityOfLandsModal}
              onKeyDown={toggleSecurityOfLandsModal}
              tabIndex={0}
              role="button"
              className={ServicesSectionStyle.service}
            >
              <img src={Service2} alt={"service"} />
              <div className={ServicesSectionStyle.title_holder}>
                <h4>ОХРАНА НА ЗЕМЕДЕЛСКИ ПЛОЩИ</h4>
              </div>
            </div>
          </div>
          {width <= 760 ? null : (
            <div className="col-lg-6 col-md-6">
              <div className={ServicesSectionStyle.service_info}>
                <h2>Нашите услуги</h2>
                <p>
                  Тук може да видите нашият пълен пакет услуги подходящи за
                  всеки тип клиенти. Благодарение на силно мотивираният ни екип,
                  фирмата е развила всички тези дейности и до днес продължава да
                  разгръща своя потенциал.
                </p>
              </div>
            </div>
          )}

          <div
            className="col-lg-3 col-md-3 col-6"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              onClick={toggleSecurityGuardsModal}
              onKeyDown={toggleSecurityGuardsModal}
              tabIndex={0}
              role="button"
              className={ServicesSectionStyle.service}
            >
              <img src={Service3} alt={"service"} />
              <div className={ServicesSectionStyle.title_holder}>
                <h4>ФИЗИЧЕСКА ОХРАНА</h4>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-3 col-6"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              onClick={toggleVideoServiceModal}
              onKeyDown={toggleVideoServiceModal}
              tabIndex={0}
              role="button"
              className={ServicesSectionStyle.service}
            >
              <img src={Service4} alt={"service"} />
              <div className={ServicesSectionStyle.centered_title_holder}>
                <h4>ВИДЕОНАБЛЮДЕНИЕ</h4>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-3 col-6"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              onKeyDown={toggleSecurityEventsModal}
              onClick={toggleSecurityEventsModal}
              tabIndex={0}
              role="button"
              className={ServicesSectionStyle.service}
            >
              <img src={Service5} alt={"service"} />
              <div className={ServicesSectionStyle.title_holder}>
                <h4>ОХРАНА НА МЕРОПРИЯТИЯ</h4>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-3 col-6"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              onKeyDown={toggleSystemSecurity}
              onClick={toggleSystemSecurity}
              tabIndex={0}
              role="button"
              className={ServicesSectionStyle.service}
            >
              <img src={Service7} alt={"service"} />
              <div className={ServicesSectionStyle.title_holder}>
                <h4>ОХРАНИТЕЛНИ СИСТЕМИ</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesSection
