import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import HomeTemplate from "../modules/home/Home.template"
import { graphql } from "gatsby"

const HomePage = ({ data }) => (
  <Layout>
    <SEO
      title="Начало"
      keywords={
        "СОТ Ямбол, охрана Ямбол, СОД ямбол, Видеонаблюдение,охрана на обекти Ямбол, физическа охрана Ямбол, цени на СоТ Ямбол"
      }
    />
    <HomeTemplate
      homeHeroSectionImage={data.homeHeroSectionImage.edges}
      aboutUsSectionImage={data.aboutUsSectionImage.edges}
    />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    homeHeroSectionImage: allFile(
      filter: { relativeDirectory: { eq: "homeHeroSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    aboutUsSectionImage: allFile(
      filter: { relativeDirectory: { eq: "aboutUsSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
